<template>
  <div class="common-bg shop-manage">
    <van-nav-bar title="店铺管理" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset class="shop-des">
        <div class="font-size-34">店铺简介</div>
        <van-cell-group inset>
          <van-field
            v-model="shopHomeMsg.shop_introduction"
            rows="5"
            autosize
            type="textarea"
            maxlength="150"
            input-align="center"
            show-word-limit
          />
        </van-cell-group>
      </van-cell-group>

      <van-cell-group inset class="home-img">
        <div class="font-size-34">店铺首页图片</div>
        <div class="df aic jcc">
          <van-uploader
            v-model="shopHomeMsg.shop_detail_image_home_path"
            :after-read="afterRead"
            :before-read="beforeRead"
            :max-count="1"
          />
        </div>
      </van-cell-group>

      <van-cell-group inset class="home-img">
        <div class="font-size-34">店铺详情页banner图片</div>
        <div class="df fdc aic jcc">
          <van-uploader
            v-model="shopHomeMsg.shop_detail_image_path_1"
            :after-read="afterRead1"
            :before-read="beforeRead"
          />
          <van-uploader
            v-model="shopHomeMsg.shop_detail_image_path_2"
            :after-read="afterRead2"
            :before-read="beforeRead"
          />
          <van-uploader
            v-model="shopHomeMsg.shop_detail_image_path_3"
            :after-read="afterRead3"
            :before-read="beforeRead"
          />
        </div>
      </van-cell-group>
    </van-form>
    <van-button type="primary" size="normal" @click="onSubmit">提交</van-button>
  </div>
</template>

<script>
import { upload, shopManage } from "../../utils/api";
import { Toast } from "vant";
export default {
  data() {
    return {
      shopHomeMsg: {
        shop_introduction: "",
        shop_detail_image_home_path: [], //店铺首页图
        shop_detail_image_path_1: [], //店铺价格
        shop_detail_image_path_2: [], //店铺价格
        shop_detail_image_path_3: [], //店铺价格
      },
    };
  },
  methods: {
    onSubmit() {
      const shop_detail_image_home_path = this.img;
      const shop_detail_image_path_1 = this.img1;
      const shop_detail_image_path_2 = this.img2;
      const shop_detail_image_path_3 = this.img3;
      shopManage({
        shop_introduction: this.shopHomeMsg.shop_introduction,
        shop_detail_image_home_path: this.img,
        shop_detail_image_path_1: this.img1,
        shop_detail_image_path_2: this.img2,
        shop_detail_image_path_3: this.img3,
      }).then((res) => {
        if (res.code === 200) {
          Toast.success("编辑成功");
          this.$router.push("/user");
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
      ) {
        this.$toast("请上传jpg或png格式的图片");
        return false;
      }
      return true;
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    afterRead1(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img1 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    afterRead2(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img2 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    afterRead3(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img3 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-manage {
  ::v-deep .van-field__control {
    text-align: right;
  }

  .shop-des {
    padding: 20px;

    .van-field {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
  }

  .home-img {
    padding: 20px;

    .van-uploader {
      margin-top: 20px;
    }
  }
}

.van-cell-group {
  margin-bottom: 20px;
}

.id-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.van-button {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  display: inherit;
}
</style>
